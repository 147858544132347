import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, vShow as _vShow, Transition as _Transition, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-h6" }
const _hoisted_2 = {
  style: {"position":"absolute","top":"25px","right":"25px"},
  height: "64",
  alt: "Cookie",
  title: "Cookie",
  src: "https://cdn2.pagisto.com/graphics/icons/cookie/cookie_ganz.svg"
}
const _hoisted_3 = {
  style: {"position":"absolute","top":"25px","right":"25px"},
  height: "64",
  alt: "Cookie in brackets",
  title: "Cookie in brackets",
  src: "https://cdn2.pagisto.com/graphics/icons/cookie/cookie_angeknabbert.svg"
}
const _hoisted_4 = { class: "column justify-start" }
const _hoisted_5 = { class: "text-subtitle1 text-weight-medium" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_q_header = _resolveComponent("q-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_p_footer = _resolveComponent("p-footer")!
  const _component_q_page_sticky = _resolveComponent("q-page-sticky")!
  const _component_q_page_container = _resolveComponent("q-page-container")!
  const _component_q_scroll_observer = _resolveComponent("q-scroll-observer")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _component_q_layout = _resolveComponent("q-layout")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createBlock(_component_q_layout, { view: "lHr lpR lff" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_header, {
        elevated: false,
        class: _normalizeClass([{
                'q-pa-lg': _ctx.$q.screen.gt.sm,
                'q-pa-md': _ctx.$q.screen.lt.md,
            }, "bg-transparent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, {
            class: "row items-center justify-between q-px-lg text-white bg-primary",
            style: {"transition":"all .3s","border-radius":"18px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_toolbar_title, {
                style: {"min-height":"35px"},
                class: "row items-center justify-between"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("img", {
                    alt: "Pagisto GmbH Logo",
                    title: "Pagisto GmbH Logo",
                    width: "147",
                    height: "20",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: `main_${_ctx.get_valid_locale(_ctx.$route.meta.lang || '')}`, hash: '' }))),
                    style: {"height":"20px","width":"auto","cursor":"pointer"},
                    src: "https://cdn.pagisto.com/two/pagisto/graphics/logos/pagisto_white.svg"
                  }, null, 512), [
                    [_directive_ripple]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_btn, {
                "aria-label": _ctx.$t('defaults.sign_in'),
                outline: "",
                type: "a",
                size: "sm",
                href: "https://my.pagisto.com",
                target: "__blank",
                label: _ctx.$t('defaults.sign_in'),
                color: _ctx.$q.dark.isActive ? 'text2' : 'accent2'
              }, null, 8, ["aria-label", "label", "color"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_q_page_container, {
        style: _normalizeStyle(`padding-top: ${ _ctx.$q.screen.gt.sm ? '98px' : '67px'}`)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view),
          _createVNode(_component_p_footer, { style: {"z-index":"100"} }),
          _createVNode(_component_q_page_sticky, {
            style: {"z-index":"101"},
            position: "bottom-right",
            offset: [50, 50]
          }, {
            default: _withCtx(() => [
              _createVNode(_Transition, {
                appear: "",
                "enter-active-class": "animated bounceInRight",
                "leave-active-class": "animated bounceOutRight"
              }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_q_btn, {
                    "aria-label": "eject icon",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: _ctx.$route.name || 'main_de-de', hash: '' }))),
                    padding: "xs",
                    round: "",
                    "text-color": "text2",
                    color: _ctx.$q.dark.isActive ? 'accent3' : 'accent',
                    icon: "eject"
                  }, null, 8, ["color"]), [
                    [_vShow, _ctx.scrollInfo.position.top > _ctx.$q.screen.height / 2]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["style"]),
      _createVNode(_component_q_scroll_observer, { onScroll: _ctx.onScroll }, null, 8, ["onScroll"]),
      _createVNode(_component_q_dialog, {
        modelValue: _ctx.open_cookies_warning,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.open_cookies_warning) = $event)),
        persistent: "",
        onShow: _cache[4] || (_cache[4] = ($event: any) => (_ctx.get_cookie_warning_data()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card, {
            style: {"max-width":"600px"},
            maximized: _ctx.$q.screen.gt.sm
          }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { style: {"position":"relative","width":"100%"} }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(Object.values(this.cookie_warning_content.title || { de: '' })[1]), 1),
                  _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
                    [_vShow, !_ctx.cookies.ilikecookies]
                  ]),
                  _withDirectives(_createElementVNode("img", _hoisted_3, null, 512), [
                    [_vShow, _ctx.cookies.ilikecookies]
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_section, { class: "column justify-start items-center no-wrap" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(this.cookie_warning_content.elements || { de: [] })[1], (element, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "row justify-start items-center no-wrap q-mt-md"
                    }, [
                      (Object.values(element.data.name)[1] === 'essentials')
                        ? (_openBlock(), _createBlock(_component_q_toggle, {
                            key: 0,
                            class: "q-mr-md",
                            disable: "",
                            "model-value": _ctx.cookies.essentials
                          }, null, 8, ["model-value"]))
                        : (_openBlock(), _createBlock(_component_q_toggle, {
                            key: 1,
                            class: "q-mr-md",
                            modelValue: _ctx.cookies[Object.values(element.data.name)[1]],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.cookies[Object.values(element.data.name)[1]]) = $event)
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(Object.values(element.data.title)[1]), 1),
                        _createElementVNode("div", {
                          class: "text-body2",
                          style: {"font-size":"0.8em"},
                          innerHTML: Object.values(element.data.description)[1]
                        }, null, 8, _hoisted_6)
                      ])
                    ]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  false
                    ? (_openBlock(), _createBlock(_component_q_btn, {
                        key: 0,
                        flat: "",
                        label: _ctx.$t('app.cookie_warning.buttons.decline'),
                        color: "text",
                        size: "sm",
                        "no-caps": ""
                      }, null, 8, ["label"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_q_space),
                  _createVNode(_component_q_btn, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.accept_all_cookies())),
                    label: _ctx.$t('app.cookie_warning.buttons.accept_all'),
                    "no-caps": "",
                    loading: _ctx.cookies_loading,
                    color: "primary"
                  }, null, 8, ["label", "loading"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["maximized"])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}