import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["alt", "title", "src"]
const _hoisted_2 = ["alt", "title", "src"]
const _hoisted_3 = {
  class: "q-ma-none text-text2",
  style: {"font-size":"0.82em"}
}
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "q-mt-md full-width" }
const _hoisted_6 = {
  style: {"font-size":"0.82em","text-align":"justify"},
  class: "q-ma-none text-text2 text-body2"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!

  return (_openBlock(), _createBlock(_component_q_toolbar, {
    class: _normalizeClass(["fit column wrap justify-start items-center bg-accent2", {
            'q-pa-xl': _ctx.$q.screen.gt.sm,
            'q-px-lg q-py-xl': _ctx.$q.screen.lt.md
        }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["fit row wrap justify-start items-center", { 'column': _ctx.$q.screen.lt.md }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'fit column justify-center items-center': _ctx.$q.screen.lt.md })
        }, [
          (!_ctx.$q.dark.isActive)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: _normalizeClass({ 'q-mb-sm': _ctx.$q.screen.lt.md }),
                height: "20",
                width: "147",
                alt: _ctx.content[0].data.logoLight?.iv[0].alt || '',
                title: _ctx.content[0].data.logoLight?.iv[0].alt || '',
                style: {"height":"20px","width":"auto"},
                src: _ctx.content[0].data.logoLight?.iv[0].url
              }, null, 10, _hoisted_1))
            : (_openBlock(), _createElementBlock("img", {
                key: 1,
                height: "20",
                width: "147",
                alt: _ctx.content[0].data.logoDark?.iv[0].alt || '',
                title: _ctx.content[0].data.logoDark?.iv[0].alt || '',
                class: _normalizeClass({ 'q-mb-sm': _ctx.$q.screen.lt.md }),
                style: {"height":"20px","width":"auto"},
                src: _ctx.content[0].data.logoDark?.iv[0].url
              }, null, 10, _hoisted_2)),
          _createElementVNode("p", _hoisted_3, _toDisplayString(Object.values(_ctx.content[0].data.copyright || '')[1]), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["full-height row justify-center items-center q-mx-lg", { 'q-my-md': _ctx.$q.screen.lt.md, 'full-width column justify-center items-center': _ctx.$q.screen.lt.sm }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(_ctx.content[0].data.links || '')[1], (link) => {
            return (_openBlock(), _createBlock(_component_q_btn, {
              key: link,
              "aria-label": Object.values(link.data.refContent || '')[1].labelShort || '',
              color: "link",
              to: { name: Object.values(link.data.routing || '')[1].routeName },
              target: "_blank",
              flat: "",
              label: Object.values(link.data.refContent || '')[1].labelShort
            }, null, 8, ["aria-label", "to", "label"]))
          }), 128))
        ], 2),
        (_ctx.$q.screen.gt.sm)
          ? (_openBlock(), _createBlock(_component_q_space, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["q-mx-lg full-height row", { 'q-mb-md': _ctx.$q.screen.lt.md, 'full-width column justify-center items-center': _ctx.$q.screen.lt.sm }])
        }, [
          _createVNode(_component_q_select, {
            modelValue: _ctx.locale,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event)),
              _ctx.set_language
            ],
            options: _ctx.locale_options,
            dense: "",
            borderless: "",
            "emit-value": "",
            "map-options": "",
            "options-dense": "",
            "option-label": opt => _ctx.$t(opt.label)
          }, null, 8, ["modelValue", "options", "onUpdate:modelValue", "option-label"]),
          _createVNode(_component_q_btn, {
            "aria-label": _ctx.$q.dark.mode === true ? 'dark' : _ctx.$q.dark.mode === 'auto' ? 'auto' : 'light',
            icon: "invert_colors",
            color: "text",
            flat: "",
            onClick: _ctx.toggle_dark_mode,
            label: _ctx.$q.dark.mode === true ? 'dark' : _ctx.$q.dark.mode === 'auto' ? 'auto' : 'light'
          }, null, 8, ["aria-label", "onClick", "label"])
        ], 2),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            rel: "noreferrer",
            "aria-label": "Pagisto Facebook Link",
            href: Object.values(_ctx.content[0].data.facebookUrl || '')[1],
            target: "__blank"
          }, [
            _createVNode(_component_q_icon, {
              color: _ctx.$q.dark.isActive ? 'secondary' : 'pagisto',
              size: "md",
              name: "facebook"
            }, null, 8, ["color"])
          ], 8, _hoisted_4)
        ])
      ], 2),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("p", _hoisted_6, _toDisplayString(Object.values(_ctx.content[0].data.hint || '')[1]), 1),
        _createElementVNode("p", {
          style: {"font-size":"0.82em","text-align":"justify"},
          class: "q-ma-none text-text2 text-body2",
          innerHTML: _ctx.til
        }, null, 8, _hoisted_7)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}