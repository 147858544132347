import gql from 'graphql-tag'

export default (lang: string) => {
    return gql`query QUERY_MESSAGE {
        data:queryCookiewarningContents {
            data {
                title {
                    ${lang}
                }
                elements {
                    ${lang} {
                        ... on Cookiewarningelements {
                            data {
                                name {
                                    iv
                                }
                                title {
                                    ${lang}
                                }
                                description {
                                    ${lang}
                                }
                            }
                        }
                    }
                }
            }
        }
    }`
}
