import gql from 'graphql-tag'

export default (lang: string) => {
    return gql`query QUERY_MESSAGE ($skip: Int!) {
        data:queryTilContents(skip: $skip, top: 1) {
            data {
                message {
                    ${lang}
                }
            }
        }
    }`
}
