import { CMS_SCHEMA_Pages } from '@/types/cms/pages'
import { API_Query_Asset, API_Query_Data_Object } from '../API'

export class CMS_SCHEMA_Footer {
    copyright?: { [key: string]: string }
    hint?: { [key: string]: string }
    facebookUrl?: string
    links?: { [key: string]: API_Query_Data_Object<CMS_SCHEMA_Pages> }
    logoLight?: { [key: string]: API_Query_Asset[] }
    logoDark?: { [key: string]: API_Query_Asset[] }

    constructor () {
        this.copyright = { de: '' }
        this.hint = { de: '' }
        this.facebookUrl = ''
        this.links = { de: { data: [new CMS_SCHEMA_Pages()] } }
        this.logoLight = { iv: [new API_Query_Asset(), new API_Query_Asset()] }
        this.logoDark = { iv: [new API_Query_Asset(), new API_Query_Asset()] }
    }
}
