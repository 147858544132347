
import { defineComponent, ref } from 'vue'

// components
import Footer from '@/components/layout/Footer.vue'
/* import Divider from '@/components/layout/Divider.vue' */
import { useMeta } from 'quasar'
import { CMS_COMPONENT_Seo } from '@/types/cms/components/seo'
import { API_Query_Data, API_Query_Data_Helper } from '@/types/API'
import { apolloProvider } from '@/boot/apollo'
import cookie_warning from '@/schemas/cookie_warning'
import { get_short_locale, get_valid_locale } from '@/boot/i18n'
import { CMS_SCHEMA_Cookie_Warning } from '@/types/cms/cookie_warning'

export default defineComponent({
    name: 'p_layouts_main',

    components: {
        'p-footer': Footer
        /* 'p-divider' : Divider */
    },

    data () {
        return {
            open_cookies_warning   : true,
            cookie_warning_content : new CMS_SCHEMA_Cookie_Warning(),
            lang                   : get_short_locale(this.$route.meta.lang ? this.$route.meta.lang : ''),
            cookies                : {
                essentials   : true,
                ilikecookies : false
            },
            cookies_loading: false,
            get_valid_locale
        }
    },

    setup () {
        const rightDrawerOpen = ref(false)
        const scrollInfo = ref({
            position: {
                top  : 0,
                left : 0
            }
        })

        const transparent_header = ref(false)

        return {
            transparent_header,
            rightDrawerOpen,
            toggleRightDrawer () {
                rightDrawerOpen.value = !rightDrawerOpen.value
            },
            scrollInfo,
            onScroll (info: { position: { top: number, left: number } }) {
                if (info.position.top > 60) {
                    transparent_header.value = true // true for transparent
                } else {
                    transparent_header.value = false
                }

                scrollInfo.value = info
            }
        }
    },

    watch: {
        '$route.path': {
            handler () {
                const route = this.$route
                const seo = route.meta.page_seo || new CMS_COMPONENT_Seo()
                const alternate_route = this.$router
                    .getRoutes()
                    .filter(x => {
                        return (
                            !x.name?.toString().includes('_root') &&
                            x.meta.type === route.meta.type &&
                            x.name !== route.name
                        )
                    })
                const default_route = this.$router
                    .getRoutes()
                    .find(x => {
                        return (
                            x.meta.type === route.meta.type &&
                            x.meta.lang === 'en-us'
                        )
                    })

                useMeta({
                    title : seo.title,
                    meta  : {
                        title              : { name: 'title', content: seo.title },
                        description        : { name: 'description', content: seo.description },
                        keywords           : { name: 'keywords', content: seo.keywords.join(', ') },
                        ogUrl              : { property: 'og:url', content: route.path },
                        ogTitle            : { property: 'og:title', content: seo.title },
                        ogImage            : { property: 'og:image', content: !seo.ogImage || seo.ogImage.length === 0 ? '' : seo.ogImage[0].url },
                        ogDescription      : { property: 'og:description', content: seo.description },
                        twitterTitle       : { property: 'twitter:title', content: seo.title },
                        twitterImage       : { property: 'twitter:image', content: !seo.ogImage || seo.ogImage.length === 0 ? '' : seo.ogImage[0].url },
                        twitterDescription : { property: 'twitter:description', content: seo.description },
                        robots             : { name: 'robots', content: seo.robots }
                    },
                    link: {
                        canonical: {
                            rel  : 'canonical',
                            href : `https://${'www.pagisto.com/!'}${route.path}`
                        },
                        alternate1: {
                            rel      : 'alternate',
                            hreflang : route.meta.lang || '',
                            href     : `https://${'www.pagisto.com/!'}${route.path}`
                        },
                        alternate2: {
                            rel      : 'alternate',
                            hreflang : alternate_route[0].meta.lang || '',
                            href     : `https://${'www.pagisto.com/!'}${alternate_route[0].path}`
                        },
                        alternate_default: {
                            rel      : 'alternate',
                            hreflang : 'x-default',
                            href     : `https://${'www.pagisto.com/!'}${default_route?.path || ''}`
                        }
                    }
                })

                // @ts-ignore
                this.$q.lang.set({ isoName: route.meta.lang })
            },
            immediate: true
        }
    },

    methods: {
        async get_cookie_warning_data () {
            this.cookie_warning_content = new CMS_SCHEMA_Cookie_Warning()

            const message_response: API_Query_Data<API_Query_Data_Helper<CMS_SCHEMA_Cookie_Warning>> = await apolloProvider.defaultClient.query({
                query       : cookie_warning(this.lang),
                fetchPolicy : 'cache-first'
            })

            this.cookie_warning_content = message_response.data.data[0].data
        },

        accept_all_cookies () {
            this.cookies_loading = true
            this.cookies.ilikecookies = true
            const cookie_keys = Object.keys(this.cookies)
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            const cookie_text = cookie_keys.map((x, i) => `${x}=${this.cookies[x]}${i !== (cookie_keys.length - 1) ? '&' : ''}`).toString()
            this.$q.cookies.set('cookie_warning_read', cookie_text, { expires: '69d', path: '/' })

            setTimeout(() => {
                this.open_cookies_warning = false
            }, 1500)
        }
    },

    created () {
        const route = this.$route
        const seo = route.meta.page_seo || new CMS_COMPONENT_Seo()
        const alternate_route = this.$router
            .getRoutes()
            .filter(x => {
                return (
                    !x.name?.toString().includes('_root') &&
                    x.meta.type === route.meta.type &&
                    x.name !== route.name
                )
            })
        const default_route = this.$router
            .getRoutes()
            .find(x => {
                return (
                    x.meta.type === route.meta.type &&
                    x.meta.lang === 'en-us'
                )
            })

        useMeta({
            title : seo.title,
            meta  : {
                title              : { name: 'title', content: seo.title },
                description        : { name: 'description', content: seo.description },
                keywords           : { name: 'keywords', content: seo.keywords.join(', ') },
                ogUrl              : { property: 'og:url', content: route.path },
                ogTitle            : { property: 'og:title', content: seo.title },
                ogImage            : { property: 'og:image', content: !seo.ogImage || seo.ogImage.length === 0 ? '' : seo.ogImage[0].url },
                ogDescription      : { property: 'og:description', content: seo.description },
                twitterTitle       : { property: 'twitter:title', content: seo.title },
                twitterImage       : { property: 'twitter:image', content: !seo.ogImage || seo.ogImage.length === 0 ? '' : seo.ogImage[0].url },
                twitterDescription : { property: 'twitter:description', content: seo.description },
                robots             : { name: 'robots', content: seo.robots }
            },
            link: {
                canonical: {
                    ref  : 'canonical',
                    href : `https://${'www.pagisto.com/!'}${route.path}`
                },
                alternate1: {
                    rel      : 'alternate',
                    hreflang : route.meta.lang || '',
                    href     : `https://${'www.pagisto.com/!'}${route.path}`
                },
                alternate2: {
                    rel      : 'alternate',
                    hreflang : alternate_route[0].meta.lang || '',
                    href     : `https://${'www.pagisto.com/!'}${alternate_route[0].path}`
                },
                alternate_default: {
                    rel      : 'alternate',
                    hreflang : 'x-default',
                    href     : `https://${'www.pagisto.com/!'}${default_route?.path || ''}`
                }
            }
        })

        if (
            this.$q.platform.userAgent === 'Googlebot' ||
            this.$q.platform.userAgent.includes('Googlebot') ||
            this.$q.platform.userAgent.includes('Chrome-Lighthouse')
        ) {
            this.open_cookies_warning = false
        } else {
            const cookie_warning_read = this.$q.cookies.has('cookie_warning_read')
            this.open_cookies_warning = !cookie_warning_read
        }

        // @ts-ignore
        this.$q.lang.set({ isoName: route.meta.lang })
    }
})
