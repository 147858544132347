
import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { modeColors } from '@/helper/dark_mode_colors'
import gql from 'graphql-tag'
import { get_short_locale } from '@/boot/i18n'
import { CMS_SCHEMA_Footer } from '@/types/cms/footer'
import { apolloProvider } from '@/boot/apollo'
import { API_Query_Data_Helper, API_Query_Data, API_Query_Data_Sgn } from '@/types/API'
import til_total from '@/schemas/til_total'
import til_query from '@/schemas/til_query'
import { moment } from '@/boot/momentjs'

export default defineComponent({
    name: 'p_components_layout_footer',

    data () {
        const { locale } = useI18n({ useScope: 'global' })

        return {
            locale,
            locale_options: [
                { value: 'en-us', label: 'defaults.languages.english' },
                { value: 'de-de', label: 'defaults.languages.german' }
            ],
            content      : [{ data: new CMS_SCHEMA_Footer() }],
            lang         : get_short_locale(this.$route.meta.lang ? this.$route.meta.lang : ''),
            init_request : true,
            til          : ''
        }
    },

    watch: {
        '$route.meta.lang': {
            handler () {
                if (!this.init_request) {
                    this.lang = get_short_locale(this.$route.meta.lang ? this.$route.meta.lang : '')
                    this.get_data()
                    this.get_til_data()
                } else {
                    this.init_request = false
                }
            },
            immediate: true
        }
    },

    methods: {
        async get_data () {
            const response: API_Query_Data<API_Query_Data_Helper<CMS_SCHEMA_Footer>> = await apolloProvider.defaultClient.query({
                query: gql`query {
                    data:queryFooterContents {
                        data {
                            copyright {
                                ${this.lang}
                            }
                            hint {
                                ${this.lang}
                            }
                            facebookUrl {
                                iv
                            }
                            links {
                                ${this.lang} {
                                    data {
                                        refContent {
                                            ${this.lang} {
                                                labelShort
                                            }
                                        }
                                        routing {
                                            ${this.lang} {
                                                routeName
                                            }
                                        }
                                    }
                                }
                            }
                            logoLight {
                                iv {
                                    url
                                    alt:metadata(path: "alt")
                                }
                            }
                            logoDark {
                                iv {
                                    url
                                    alt:metadata(path: "alt")
                                }
                            }
                        }
                    }
                }`
            })

            this.content = response.data.data
        },

        toggle_dark_mode () {
            const m = this.$q.dark.mode.toString()
            let newM: boolean | 'auto' = 'auto'
            if (m === 'true') newM = false
            if (m === 'auto') newM = true

            this.$q.dark.set(newM)
            this.$q.localStorage.set('dark_mode', this.$q.dark.mode.toString())

            modeColors(this.$q.dark.isActive)

            this.$store.dispatch('helper/A_TOGGLE_DARK', this.$q.dark.isActive)
        },

        set_language () {
            this.$q.localStorage.set('language', this.locale)

            const routes = this.$router.getRoutes()
            const route = this.$route

            let new_route_name = route.name || 'main'
            let found_route = false

            routes.map(x => {
                if (x.meta.lang === this.locale) {
                    if (x.meta.type === route.meta.type) {
                        new_route_name = x.name || 'main_de-de'
                        found_route = true
                    } else if (!found_route) {
                        new_route_name = 'main_de-de'
                    }
                }
            })

            const new_route = {
                name   : new_route_name,
                query  : route.query,
                params : route.params
            }

            this.$router.push(new_route)
            this.get_data()
            this.get_til_data()

            moment.locale(this.locale.split('-')[0])
        },

        async get_til_data () {
            this.til = ''

            const total_response: API_Query_Data_Sgn<{ total: number }> = await apolloProvider.defaultClient.query({
                query       : til_total(),
                fetchPolicy : 'cache-first'
            })

            const total = total_response.data.data.total - 1 || 0
            const random = Math.random()
            const skip = Math.round(total * random)

            const message_response: API_Query_Data<API_Query_Data_Helper<{ message: string }>> = await apolloProvider.defaultClient.query({
                query     : til_query(this.lang),
                variables : {
                    skip
                },
                fetchPolicy: 'cache-first'
            })

            this.til = Object.values(message_response.data.data[0].data.message)[1]
        }
    },

    created () {
        this.get_data()
    },

    mounted () {
        this.get_til_data()
    }
})
